
const digest = 'a2cafd5f57f17eb9160faf0df6c538257db6e467c74c4b76a5d5afd902a9f218';
const css = `._message_11prj_1 {
  align-content: center;
  display: flex;
  font-weight: 600;
}

._message_11prj_1 svg {
  margin-right: 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"message":"_message_11prj_1"};
export { css, digest };
  