
const digest = 'c3e804042657492a26d70dc04b48adc142352709f08fb18df6b0f0981adce336';
const css = `._cardContainer_69bpo_1 {
  padding: 46px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  overflow: hidden;
}

._heading6_69bpo_9 {
  align-self: initial;
  flex-grow: 0;
  color: var(--color-brand);
  line-height: 1.3;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

._autoLayoutCenter_69bpo_20 {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: initial;
  flex-grow: 0;
  flex-direction: row;
  margin-left: 0;
  margin-top: inherit;
}

._flex_69bpo_31 {
  flex-direction: column;
  width: 294px;
  align-self: initial;
  flex-grow: 0;
  flex: 0 0 auto;
  display: flex;
  padding-top: 1.75rem;
}

._heading2_69bpo_41 {
  line-height: 1.3;
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
}

._descriptionBox_69bpo_48 {
  align-self: initial;
  flex-grow: 0;
  padding: 1.25rem 0 1.75rem 0;
}

._text_69bpo_54 {
  font-size: 1rem;
  color: #606d78;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

._buttonContainer_69bpo_62 {
  align-self: initial;
  flex-grow: 0;
  padding-top: 0.625rem;
}

._button_69bpo_62 {
  background-color: var(--color-brand);
  color: #fff;
  transition: background-color 0.2s ease 0s, border-color, color;
  border: none;
  border-radius: 0.25rem;
  padding: calc(calc(2.25rem - 1rem) * 0.5);
  font-weight: 600;
  font-size: 1rem;
}

._button_69bpo_62:hover {
  background-color: #2f20a1;
}

._boxImg_69bpo_83 {
  width: 100%;
  height: 100%;
  margin-top: -0.875rem;
  transform: scale(1.2);
  object-fit: contain;
  max-width: 409px;
  min-width: 320px;
}

._imgContainer_69bpo_93 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  width: 100%;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"cardContainer":"_cardContainer_69bpo_1","heading6":"_heading6_69bpo_9","autoLayoutCenter":"_autoLayoutCenter_69bpo_20","flex":"_flex_69bpo_31","heading2":"_heading2_69bpo_41","descriptionBox":"_descriptionBox_69bpo_48","text":"_text_69bpo_54","buttonContainer":"_buttonContainer_69bpo_62","button":"_button_69bpo_62","boxImg":"_boxImg_69bpo_83","imgContainer":"_imgContainer_69bpo_93"};
export { css, digest };
  