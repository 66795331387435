
const digest = '089fb826682f60d76dbe6e7b6279263bef1c47a8ecf65f7181e5da9e8573572e';
const css = `._card_rrs2e_1 {
  background: #fff;
  border: 1px solid #e7e7ed;
}

._withPadding_rrs2e_6 {
  padding: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"card":"_card_rrs2e_1","withPadding":"_withPadding_rrs2e_6"};
export { css, digest };
  